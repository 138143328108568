import { get } from 'api/api';
import { AssetWithInfo, InfoSpread, Ohlcv } from 'interfaces';
import { getBaseApi } from '../base';

const basePath = 'assets';
export default {
  ...getBaseApi(`${basePath}`),
  getByExchange: (exchangeId: number): Promise<AssetWithInfo[]> => {
    return get(`${basePath}?exchangeId=${exchangeId}`)
  },
  getStats: (id: number): Promise<AssetWithInfo[]> => {
    return get(`${basePath}/${id}`);
  },
  getSpreads: (id: number, exchangeName: string): Promise<InfoSpread[]> => {
    return get(`${basePath}/${id}/spreads?exchange=${exchangeName}`)
  },
  getCandles: (id: number): Promise<Ohlcv[]> => {
    return get(`${basePath}/${id}/candles`)
  }
}


