import React from 'react';
import Container from 'react-bootstrap/Container';
import { LeftNav } from 'components/Layout';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { AssetWithInfo, InfoSpread, Ohlcv } from 'interfaces';
import AssetView from 'components/Asset/Asset';
import api from 'api';

export interface LineSeries {
  value: number
  time: number
}

export interface ChartData {
  name: string
  data: LineSeries[]
}

interface AssetProps extends RouteComponentProps<any> {
  history: any
  location: any
}

interface AssetState {
  assets: AssetWithInfo[]
  asset?: AssetWithInfo
  stats: AssetWithInfo[]
  priceSeries: ChartData[]
  fundingSeries: ChartData[]
  candles: Ohlcv[]
  selectedFunding: string[]
  selectedPrice: string[]
}

class Assets extends React.Component<AssetProps, AssetState> {
  private _isMounted: boolean = false;
  private _isLoading: boolean = false;
  constructor(props: AssetProps) {
    super(props);
    this._isMounted = true;
    this.state = {
      assets: [],
      stats: [],
      candles: [],
      fundingSeries: [],
      priceSeries: [],
      selectedFunding: ['ftx'],
      selectedPrice: ['ftx']
    }
  }

  componentDidUpdate = async() => {
    const { asset } = this.state;
    const symbol = this.props.location.pathname.split('/').pop();
    if (symbol && asset && symbol !== this.state.asset?.base) {
      await this.loadStats(symbol);
      // Select all tiles by default
      const selectedFunding: string[] = [];
      const selectedPrice: string[] = [];
      for (const stat of this.state.stats) {
        selectedFunding.push(stat.exchange.name);
        selectedPrice.push(stat.exchange.name);
      }
      this.setState({ selectedFunding, selectedPrice });
    }
  }

  onToggleFunding = async (name: string) => {
    const selectedFunding = [...this.state.selectedFunding];
    const idx = selectedFunding.indexOf(name);
    // If not found, user is selecting it. Fetch data & add to array
    if (idx === -1 && this.state.asset?.id) {
      selectedFunding.push(name);
    } else {
      selectedFunding.splice(idx, 1);
      const fIdx = this.state.fundingSeries.findIndex(f => f.name === name);
      if (fIdx !== -1) {
        const fundingSeries = [...this.state.fundingSeries];
        fundingSeries.splice(fIdx, 1);
        this.setState({ fundingSeries });
      }
    }
    this.setState({ selectedFunding }, () => {
      if (idx === -1) {
        this.loadSpreads();
      }
    });
  }

  onTogglePrice = async (name: string) => {
    const selectedPrice = [...this.state.selectedPrice];
    const idx = selectedPrice.indexOf(name);
    // If not found, user is selecting it. Fetch data & add to array
    if (idx === -1 && this.state.asset?.id) {
      selectedPrice.push(name);
    } else {
      selectedPrice.splice(idx, 1);
      const fIdx = this.state.priceSeries.findIndex(f => f.name === name);
      if (fIdx !== -1) {
        const priceSeries = [...this.state.priceSeries];
        priceSeries.splice(fIdx, 1);
        this.setState({ priceSeries });
      }
    }
    this.setState({ selectedPrice }, () => {
      if (idx === -1) {
        this.loadSpreads();
      }
    });
  }
  
  loadStats = async(symbol: string) => {
    if (!this._isMounted) {
      return;
    }
    // Fetch stats
    const asset = this.state.assets.find(a => a.base.toLowerCase() === symbol.toLowerCase());
    if (!asset) {
      console.log('loadStats no asset ' + symbol);
      return;
    }

    const stats = await api.assets.getStats(asset.id);
    const candles = await api.assets.getCandles(asset.id);
    if (!this._isMounted) {
      return;
    }
    this.setState({ stats, asset, candles }, this.loadSpreads);
  }

  loadSpreads = async() => {
    const fundingSeries: ChartData[] = [];
    const priceSeries: ChartData[] = [];
    // Get unique selected exchanges
    const exchanges = this.getSelectedExchanges();
    if (!this.state.asset?.id) {
      console.error('loadSpreads no asset');
      return;
    }
    const assetId = this.state.asset.id;
    // Fetch spreads for each selected exchange
    const spreads = await Promise.all(exchanges.map(name => api.assets.getSpreads(assetId, name).catch(() => { return [] as InfoSpread[]})));
    const selectedPrice = [...this.state.selectedPrice];
    const selectedFunding = [...this.state.selectedFunding];
    for (let i = 0; i < spreads.length; i++) {
      const name = exchanges[i];
      // Only add to series if selected
      if (selectedPrice.includes(name)) {
        priceSeries.push({ name, data: spreads[i].map(c => ({ value: c.priceSpread ?? 0, time: c.createdAt })).reverse() });
      }
      if (selectedFunding.includes(name)) {
        fundingSeries.push({ name, data: spreads[i].map(c => ({ value: c.fundingSpread ?? 0, time: c.createdAt })).reverse() });
      }
    }
    this.setState({ fundingSeries, priceSeries });
  }

  getSelectedExchanges = () => {
    const {selectedPrice, selectedFunding} = this.state;
    const exchanges: string[] = [];
    for (const name of selectedPrice.concat(selectedFunding)) {
      if (!exchanges.includes(name)) {
        exchanges.push(name);
      }
    }
    return exchanges;
  }

  loadAssets = async() => {
    if (!this._isMounted) {
      return;
    }
    setTimeout(() => this.loadAssets(), 15000);
    if (this._isLoading) {
      console.log('still loading, skip');
      return;
    }
    this._isLoading = true;
    try {
      const assets = await api.assets.getByExchange(8);
      this.setState({ assets }, () => this.loadStats(this.state.asset?.base ?? assets[0].base));
    } catch (err) {
      console.error('Failed to fetch asseets: ', err);
    }
    this._isLoading = false;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  componentDidMount = async() => {
    const { history } = this.props;
    try {
      const assets = await api.assets.getByExchange(8);
      // If no asset selected, default to first
      const symbol = this.props.location.pathname.split('/').pop();
      if (!symbol?.length) {
        history.push(`/assets/${assets[0].base}`);
        return;
      } else {
        const search = assets.find(a => a.base.toLowerCase() === symbol.toLowerCase());
        if (!search) {
          history.push(`/assets/${assets[0].base}`);
          return;
        }
      }
      this.setState({ assets }, () => this.loadStats(symbol));
      setTimeout(() => this.loadAssets(), 5000);
    } catch (err) {
      console.error('Failed to fetch assets:', err);
    }
  }

  render = () => {
    const { asset, assets, stats, fundingSeries, priceSeries, candles, selectedFunding, selectedPrice } = this.state;
    return (
      <Container fluid className="assets">
        <Row className='h-100'>
          <Col xs={'auto'}>
            <LeftNav assets={assets} />
          </Col>
          {asset && (
            <AssetView
              asset={asset}
              stats={stats}
              selectedFunding={selectedFunding}
              selectedPrice={selectedPrice}
              onTogglePrice={this.onTogglePrice}
              onToggleFunding={this.onToggleFunding}
              fundingSeries={fundingSeries}
              priceSeries={priceSeries}
              candles={candles}
            />)}
        </Row>
      </Container>
    )
  }
}

export default withRouter(Assets);
