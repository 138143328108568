import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {NavOval, Logo, NavOverview, NavAssets, NavWallet, DownArrow} from 'components/shared/Svg';
import { Link } from 'react-router-dom';
import { AssetWithInfo } from 'interfaces';

const NavItem = ({selected, children, route, SvgIcon, className, onClick}: {
  selected: boolean
  children: any
  route: string
  SvgIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  className?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}) => (
  <Row className={(className ?? '') + ' nav-item ' + (selected ? 'selected' : '')}>
    <Col xs='auto' className='oval'>{selected && <NavOval />}</Col>
    <Col xs={'auto'} className='nav-item-icon'>
      {SvgIcon && <SvgIcon />}
    </Col>
    <Col className='nav-item-link m-auto'>
      <Link onClick={onClick} to={route}>{children}</Link>
    </Col>
  </Row>
)

const NavAssetItem = ({selected, asset}: {
  selected: boolean
  asset: AssetWithInfo
}) => (
  <Row className={' nav-asset-item ' + (selected ? 'selected' : '')}>
    <Col className='nav-item-link m-auto'>
      <Link to={`/assets/${asset.base}`}>
        {asset.base} / {asset.quote}
        <span className='summary'>({asset.info?.priceChange}%) ${Number(asset.info?.price).toLocaleString()}</span>
      </Link>
    </Col>
  </Row>
)

const LeftNav = ({ assets }: { assets: AssetWithInfo[] }) => {
  const route = window.location.pathname;
  const [collapsed, setCollapsed] = React.useState(!route.includes('/assets'));
  return (
  <Row className='left-nav'>
    <Col style={{ maxHeight: '100vh', overflow: 'auto' }}>
      <Logo className='logo'/>
      <Row className='header'>
        <h2>HAVANA</h2>
      </Row>
      <NavItem SvgIcon={NavOverview} route={'/'} selected={route === '/'} >
        Overview
      </NavItem>
      <NavItem SvgIcon={NavAssets} route={'/assets'} selected={route.includes('/assets')}>
        Assets
        <span className='btn-collapse' onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCollapsed(!collapsed);
        }} >
          <DownArrow />
        </span>
      </NavItem>
      <Row className={'asset-list ' + (collapsed ? 'collapsed' : '')}>
        <Col style={{ maxHeight: 'calc(100vh - 320px)', overflow: 'auto' }}>
          {assets.map((a, i) => <NavAssetItem key={i} asset={a} selected={route === `/assets/${a.base}`} />)}
        </Col>
      </Row>
      <NavItem SvgIcon={NavWallet} route={'/wallet'} selected={route === '/wallet'}>
        Wallet History
      </NavItem>
    </Col>
  </Row>
)}

export default LeftNav;