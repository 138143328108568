import api from 'api/api';

export interface NewUser {
  name: string
}

export interface User {
  name: string
}

const basePath = 'users';
export default {
  getAll: (): Promise<User[]> => {
    return api.get(basePath, { isAdmin: true });
  },
  getById: (id: number): Promise<User> => {
    return api.get(`${basePath}/${id}`);
  },
  create: (data: NewUser): Promise<User> => {
    return api.post(`${basePath}`, {...data, isAdmin: true});
  },
  updateById: (id: number, data: Partial<NewUser>): Promise<void> => {
    return api.patch(`${basePath}/${id}`, {...data, isAdmin: true});
  },
  deleteById: (id: number): Promise<void> => {
    return api.delete(`${basePath}/${id}`, { isAdmin: true });
  }
}