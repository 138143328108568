import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { AssetWithInfo, Ohlcv } from 'interfaces';

import { GraphGreen, GraphRed, ArrowRed, ArrowGreen, GlowRed, GlowGreen } from 'components/shared/Svg';
import { ChartMulti, ChartLineSeries } from 'components/shared';
import { ChartData } from 'views/Assets';
// import moment from 'moment';

const FundingTile = ({ stat, selected, onClick }: {
stat: AssetWithInfo
selected: boolean
onClick: React.MouseEventHandler<HTMLDivElement>
}) => (
  <Col onClick={onClick} xs={3} className={selected ? 'spread-tile-selected' : 'spread-tile'}>
    <Row>
      <Col xs={5}><h3>{stat.exchange.name}</h3></Col>
      <Col className='spread-change'>{stat.info?.fundingSpread}</Col>
    </Row>
    <Row>
      <Col className={'graph'}>
        {Number(stat.info?.aprPerc) < 0 ? <GraphRed className='graph-red' /> : <GraphGreen className='graph-green' />}
        {Number(stat.info?.aprPerc) < 0 ? <ArrowRed className='arrow' /> : <ArrowGreen className='arrow'/>}
        <span className={' text-' + (Number(stat.info?.aprPerc) < 0 ? 'danger': 'success')}>{stat.info?.aprPerc}%</span>
      </Col>
    </Row>
    {Number(stat.info?.aprPerc) < 0 ? <GlowRed className='glow-red' /> : <GlowGreen className='glow-green' />}
  </Col>
)

const PriceTile = ({ stat, selected, onClick }: {
  stat: AssetWithInfo
  selected: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  }) => (
    <Col onClick={onClick} xs={3} className={selected ? 'spread-tile-selected' : 'spread-tile'}>
      <Row>
        <Col xs={5}><h3>{stat.exchange.name}</h3></Col>
        <Col className='spread-change'>{Number(stat.info?.priceSpread).toLocaleString()}</Col>
      </Row>
      <Row>
        <Col className={'graph'}>
          {Number(stat.info?.priceSpreadPerc) < 0 ? <GraphRed className='graph-red' /> : <GraphGreen className='graph-green' />}
          {Number(stat.info?.priceSpreadPerc) < 0 ? <ArrowRed className='arrow' /> : <ArrowGreen className='arrow'/>}
          <span className={' text-' + (Number(stat.info?.priceSpreadPerc) < 0 ? 'danger': 'success')}>{stat.info?.priceSpreadPerc}%</span>
        </Col>
      </Row>
      {Number(stat.info?.priceSpreadPerc) < 0 ? <GlowRed className='glow-red' /> : <GlowGreen className='glow-green' />}
    </Col>
  )

const AssetView = ({ asset, stats, candles, fundingSeries, priceSeries, onTogglePrice, onToggleFunding, selectedFunding, selectedPrice }: {
  asset: AssetWithInfo
  stats: AssetWithInfo[]
  fundingSeries: ChartData[]
  priceSeries: ChartData[]
  candles: Ohlcv[]
  selectedFunding: string[]
  selectedPrice: string[]
  onTogglePrice: (name: string) => Promise<void>
  onToggleFunding: (name: string) => Promise<void>
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  // const offsetSeconds = moment().utcOffset()*60;
  // const lineSeries = spreads.map(c => ({ value: c.priceSpread, time: c.createdAt })).reverse();
  // const fundingSeries = spreads.map(c => ({ value: c.fundingSpread, time: c.createdAt })).reverse();
  // Convert to local timezone
  const ohlcv = candles.map(c => ({ ...c, time: c.createdAt })).reverse();
  return (
  <Col className='asset-view'>
    <Row className='heading'>
      <Col xs='auto'>
        <h1>{asset.base} / {asset.quote}</h1>
      </Col>
      <Col xs='auto'>
        <h2>P:{asset.info.price} V:{asset.info.volume24} F:{asset.info.fundingRate} A:{asset.info.aprPerc}</h2>
      </Col>
    </Row>
    <Row className='sub-heading'>
      <Col>
        <h3>Funding Spread</h3>
      </Col>
    </Row>
    <Row className='spread-tiles'>
      {stats.map((s, i) => (
        <FundingTile
          key={i}
          onClick={isLoading ? () => {/* */} : async () => {
            setIsLoading(true)
            try {
              await onToggleFunding(s.exchange.name);
            } catch (err: any) {
              console.error('FundingTile failed: ' + err.message);
            }
            setIsLoading(false);
          }}
          selected={selectedFunding.includes(s.exchange.name)}
          stat={s}
        />)
      )}
    </Row>
    <Row>
      <Col xs={11} style={{ height: 300, maxWidth: '99%' }}>
        <ChartLineSeries data={fundingSeries} />
      </Col>
    </Row>
    <Row className='sub-heading'>
      <Col>
        <h3>Price Spread</h3>
      </Col>
    </Row>
    <Row className='spread-tiles'>
      {stats.map((s, i) => (
        <PriceTile
          key={i}
          onClick={isLoading ? () => {/* */} : async () => {
            setIsLoading(true)
            try {
              await onTogglePrice(s.exchange.name);
            } catch (err: any) {
              console.error('PriceTile failed: ' + err.message);
            }
            setIsLoading(false);
          }}
          selected={selectedPrice.includes(s.exchange.name)}
          stat={s}
        />)
      )}
    </Row>
    <Row>
      <Col xs={11} style={{ height: 500, maxWidth: '99%' }}>
        <ChartMulti id='price-chart' ohlcv={ohlcv} lineSeries={priceSeries} />
      </Col>
    </Row>
  </Col>
)}

export default AssetView;
