import api from 'api/api';

export interface LoginPayload {
  username: string
}

export interface LoginResponse {
  username: string
}

export async function login(data: LoginPayload): Promise<LoginResponse> {
  if (!data.username?.length) {
    throw Error('Invalid credentials');
  }
  const resp = await api.post('auth/login', data);
  return resp;
}

export default {
  login
}