import 'react';
import './styles/dark.theme.scss';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faExternalLinkAlt, faChevronCircleLeft, faLock, faCheck, faUserCog,
  faSignOutAlt, faUserPlus, faCog, faPlus, faMinus, faChevronDown, faChevronUp,
  faChevronLeft, faChevronRight, faTimes, faTrash, faQuestionCircle, faChartBar, faWallet, faPollH, faExclamation, faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { default as Router } from './Router';

  // Setup fontawesome so we can access icons via string instead of explicitly importing each
library.add(faSpinner, faLock, faExternalLinkAlt,faChevronCircleLeft, faCheck, faUserCog,
  faSignOutAlt, faUserPlus, faCog, faPlus, faMinus, faChevronDown, faChevronUp, faChevronLeft,
  faChevronRight, faTimes, faTrash, faQuestionCircle, faChartBar, faWallet, faPollH, faExclamation, faSave, faTrashAlt
  );

  // NOTE: Below code doesn't work
  // We hard import dark theme up top

  // loadThemes = () => {
  //   const themes = ['light', 'dark'];
  //   let theme = AppStore.get('theme') as string ?? 'dark';
  //   if (themes.indexOf(theme) === -1) {
  //     console.error(`Theme "${theme}" is invalid, default to light`);
  //     theme = 'light';
  //   }
  //   try {
  //     // require(`./styles/${theme}.theme.scss`);
  //   } catch (err) {
  //     console.error('Failed to require theme, default to light....');
  //     theme = 'light';
  //     // require('./styles/light.theme.scss');
  //   }
  //   AppStore.set('theme', theme);
  // }

const App = ({ history }: any) => (
  <div>
    <Router history={history}/>
  </div>
)

export default App;
