/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

// const resolutionMap = { '1m': 1, '3m': 1, '5m': 5, '10m': 5, '15m': 5, '30m': 5, '1h': 60, '2h': 60, '3h': 60, '4h': 60, '8h': 60, '1D': 'D', '2D': 'D', '1W': 'D' };
const supportedResolutions = [ '1', '3', '5', '10', '15', '30', '60', '120', '180', '240', '480', 'D', 'W' ];
const defaultSymbol = 'BitMEX:XBTUSD';

class TVChartWrapper extends React.Component<any, any> {
  widget: any;
  constructor(props: any) {
    super(props);

    this.state = {
      options: {
        alignLabels: true,
        grid: {
          vertLines: {
            color: 'rgba(70, 130, 180, 0.2)',
            style: 1,
            visible: true,
          },
          horzLines: {
            color: 'rgba(70, 130, 180, 0.2)',
            style: 1,
            visible: true,
          },
        },
        layout: {
          backgroundColor: 'transparent',
        },
        timeScale: {
          // rightOffset: 12,
          barSpacing: 3,
          autoScale: true,
          // fixLeftEdge: true,
          // lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          // borderColor: "#fff000",
          visible: true,
          timeVisible: true,
          secondsVisible: false
        }
      }
    }
  }

  componentDidMount = () => {
    this.addScriptTags();
    setTimeout(() => this.initChart(), 2000);
  }

  widgetReady = () => {
    console.log('READYT');
  }

  initChart = (retries = 0) => {
    if (retries > 200) {
      console.error('Chart still not ready');
      return;
    }
    const tvWindow = (window as any).TradingView;
    if (tvWindow) {
      console.log(new Date, 'Creating widget');
      this.widget = new tvWindow.widget(this.getWidgetOptions());
      if (document.getElementsByTagName('iframe')[0]?.contentWindow) {
        console.log('add widget ready');
        (document.getElementsByTagName('iframe')[0].contentWindow as any).widgetReady = this.onReady;
      }
      console.log(new Date, 'onChartReady')
      // this.widget.onChartReady(this.onReady);
    } else {
      setTimeout(() => this.initChart(++retries), 10);
    }
  }

  onReady = () => {
    console.log('READY', this.props.data);
  }

  addScriptTags = () => {
    const script = document.createElement('script');
    script.src = process.env.PUBLIC_URL + '/charting_lib/charting_library.min.js';
    console.log(new Date, 'Adding script ' + script.src);
    // script.src = 'https://havana.bitv.app/charting_lib/charting_library.min.js';
    script.async = true;
    document.body.appendChild(script);
  }

  getWidgetOptions = () => {
    const exchanges = [ 'bitmex', 'binance', 'bitfinex', 'bitstamp', 'coinbasepro', 'all' ];
    return {
      debug: process.env.NODE_ENV === 'development',
      interval: 60,
      container_id: 'tv_chart_container',
      library_path: '/charting_library/',
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'tradingview.com',
      user_id: 'kobermeit',
      fullscreen: false,
      autosize: true,
      // load_last_chart: true,
      // saved_data: {},
      studies_overrides: {},
      timezone: localStorage.getItem('tv_timzeone') ?? 'UTC',
      symbol: defaultSymbol,
      locale: 'en',
      disabled_features: [  ],
      enabled_features: [ 'study_templates', 'move_logo_to_main_pane', 'high_density_bars' ],
      settings_adapter: {
        initialSettings: {
          'chartproperties': localStorage.tv_chartproperties || {}
        },
        setValue: (key: any, value: any) => {
          localStorage['tv_' + key] = value;
        },
        removeValue: (key: any) => {
          localStorage['tv_' + key] = '';
        }
      },
      datafeed: {
        onReady: (cb: any) => {
          const config = {
            supported_resolutions: supportedResolutions,
            exchanges
          };
          console.log('onReady config: ', config);
          setTimeout(() => cb(config), 0);
        },
        getBars: async(_symbolInfo: any, _res: any, _fromTime: any, _toTime: any, onHistoryCallback: any) => {
          // TODO: handle
          const data = this.props.data;
          console.log('GET BARS', data);
          setTimeout(() => onHistoryCallback(data, { noData: (data.length < 1) }), 0);
        },
        searchSymbols: (_userInput: any, _exchange: any, _symbolType: any, onResultReadyCallback: any) => {
          // TODO: handle, probably disable
          onResultReadyCallback([]);
        },
        resolveSymbol: async() => {
          // _symbolName: any, _onSymbolResolvedCallback: any, _onResolveErrorCallback: any
          // TODO: handle changing asset (probably disable)
          return null;
        },
        subscribeBars: () => {
          // _symbolInfo: any, _resolution: any, _onRealtimeCallback: any
          // TODO: handle websocket
        },
        unsubscribeBars: () => {
          // TODO: handle websocket
        },
        calculateHistoryDepth: () => {
          // args: resolution, resolutionBack, intervalBack
          // NOTE: The minimum data you can get is 24hrs (1 D), no less.
          // return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined
          return undefined;
        },
        getMarks: () => {}, // args: symbolInfo, startDate, endDate, onDataCallback, resolution
        getTimeScaleMarks: () => {}, // args: symbolInfo, startDate, endDate, onDataCallback, resolution
        getServerTime: () => {} // args: cb
      },
      theme: 'Dark',
      overrides: {
        'paneProperties.crossHairProperties.color': '#989898',
        'mainSeriesProperties.showCountdown': false,
        // "mainSeriesProperties.backgroundColor": "rgba(0, 0, 0, 1)",
        'paneProperties.background': 'rgba(0, 0, 0, 1)',
        'scalesProperties.backgroundColor': 'rgba(0, 0, 0, 1)',
        'paneProperties.vertGridProperties.color': '#161616', // grid and other stuff
        'paneProperties.horzGridProperties.color': '#161616', // grid and other stuff
        'symbolWatermarkProperties.color': '#161616', // watermark
        'scalesProperties.textColor': 'white',
        'scalesProperties.lineColor': 'white',
        // "mainSeriesProperties.candleStyle.wickUpColor": '#336854',
        // "mainSeriesProperties.candleStyle.wickDownColor": '#7f323f',
        // "mainSeriesProperties.candleStyle.upColor": '#76F94C',
        // "mainSeriesProperties.candleStyle.downColor": '#EA3223',
        // "mainSeriesProperties.candleStyle.borderUpColor": '#76F94C',
        // "mainSeriesProperties.candleStyle.borderDownColor": '#EA3223',
      }
    };
  }

  render() {
    const tvStyle = { height: '100vh', width: '100%' };
    return this.props.data.length > 0 ? (
      <div id={ 'tv_chart_container' } style={ tvStyle } />
    ) : 'No chart data.'
  }
}

export default TVChartWrapper