import React from "react";
import './lightweight.min.js';

// Hardcode colors for each exchange (based on logo)
const colorMap: Record<string, string> = {
  ftx: '#00B4C9', // Blue
  binance: '#F0B90B', // yellow
  okex: '#1231AF' // darker blue
}

class ChartMulti extends React.Component<any, any> {
  chart: any;
  ohlcv: any;
  lineSeries: any[] = [];
  constructor(props: any) {
    super(props);

    this.state = {
      test: true
   
    }
  }

  componentDidUpdate = () => {
    this.ohlcv.setData(this.props.ohlcv);
    // If any series added or removed, reset
    if (this.props.lineSeries.length !== this.lineSeries.length) {
      for (const series of this.lineSeries) {
        this.chart.removeSeries(series);
      }
      this.lineSeries = [];
      for (const series of this.props.lineSeries) {
        this.addLineSeries(series.name, series.data);
      }
    }
    for (let i = 0; i < this.props.lineSeries.length; i++) {
      this.lineSeries[i].setData(this.props.lineSeries[i].data);
    }
  }

  componentDidMount = () => {
    const Lchart = (window as any).LightweightCharts;
    this.chart = Lchart.createChart(document.getElementById(this.props.id), {
      timeScale: {
        // rightOffset: 12,
        barSpacing: 3,
        autoScale: true,
        // fixLeftEdge: true,
        // lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        // borderColor: "#fff000",
        visible: true,
        timeVisible: true,
        secondsVisible: false
      },
      layout: {
        backgroundColor: 'transparent',
        textColor: 'rgba(70, 130, 180, 1)'
      },
      grid: {
        vertLines: {
          color: 'rgba(70, 130, 180, 0.2)',
          style: 1,
          visible: true,
        },
        horzLines: {
          color: 'rgba(70, 130, 180, 0.2)',
          style: 1,
          visible: true,
        },
      },
      overlayPriceScales: {
        color: 'white',
        scaleMargins: {
          top: 0.6,
          bottom: 0,
        }
      },
      rightPriceScale: {
        color: 'white',
        autoScale: true,
        scaleMargins: {
          top: 0.1,
          bottom: 0.08,
        }
      }
    });
    this.ohlcv = this.chart.addCandlestickSeries({
      pane: 0,
      upColor: '#6495ED',
      downColor: '#FF6347',
      borderVisible: false,
      wickVisible: true,
      borderColor: '#000000',
      // wickColor: '#000000',
      borderUpColor: '#4682B4',
      borderDownColor: '#A52A2A',
      wickUpColor: '#4682B4',
      wickDownColor: '#A52A2A',
    });


    const ohlcv = [...this.props.ohlcv].map((o: any) => ({
      open: o.open,
      low: o.low,
      high: o.high,
      close: o.close,
      time: o.time
    }));
    let hasError = false;
    let prev = 0;
    for (const o of ohlcv) {
      const { time } = o;
      if (prev && Math.abs(time - prev) !== 60) {
        console.log('TIME ERROR: ', { time, prev, row: o });
        hasError = true;
        // break;
      }
      prev = time;
    }

    if (!hasError) {
      this.ohlcv.setData(ohlcv);      
    }
    // // Add additional line series
    for (const series of this.props.lineSeries) {
      this.addLineSeries(series.name, series.data);
    }
    
    const volumeSeries = this.chart.addHistogramSeries({
      secondary: 'volume',
      color: 'rgba(70, 130, 180, 0.2)',
      priceScaleId: '',
      pane: 0
    });
    volumeSeries.setData([...this.props.ohlcv].map((i: any) => ({ time: i.time, value: i.volume ?? 0 })));
  }

  addLineSeries(name: string, data: any) {
    const secondSeries = this.chart.addLineSeries({
      title: name,
      priceFormat: {
        // minMove: 1,
        precision: 8,
      },
      color: colorMap[name] ?? '#ff0000',
      pane: 1
    });
    secondSeries.setData(data);
    this.lineSeries.push(secondSeries);
  }

  render() {
    
    return this.props.ohlcv.length > 0 || this.props.lineSeries.length > 0 ? (
      <div id={this.props.id} style={{ width: '100%', height: '100%' }}></div>
    ) : 'No chart data.'
  }
}

export default ChartMulti