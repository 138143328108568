import 'react';
import Container from 'react-bootstrap/Container';
import { LeftNav } from 'components/Layout';
import { withRouter } from 'react-router-dom';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

const Wallet = () => (
  <Container fluid className="home">
    <Row className='h-100'>
      <Col xs={'auto'}>
        <LeftNav assets={[]}/>
      </Col>
      <Col className='heading'><h1>WALLET</h1></Col>
    </Row>
  </Container>
)

export default withRouter(Wallet);
