import api from './api';

interface BaseFunctions {
  getAll: () => Promise<any>
  getById: (id: string|number) => Promise<any>
  create: (data: any) => Promise<any>
  updateById: (id: string|number, data: any) => Promise<any>
  deleteById: (id: string|number) => Promise<any>
}
export function getBaseApi(basePath: string): BaseFunctions {
  return {
    getAll: () => {
      return api.get(basePath);
    },
    getById: (id) => {
      return api.get(`${basePath}/${id}`);
    },
    create: (data) => {
      return api.post(`${basePath}`, data);
    },
    updateById: (id, data) => {
      return api.patch(`${basePath}/${id}`, data);
    },
    deleteById: (id) => {
      return api.delete(`${basePath}/${id}`);
    }
  }
}