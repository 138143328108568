import React from "react";
import Chart from 'kaktana-react-lightweight-charts'

class ChartLineSeries extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      options: {
        alignLabels: true,
        priceScale: {
          scaleMargins: {
            top: 0.05,
            bottom: 0.55,
          },
          borderVisible: true,
        },
        grid: {
          vertLines: {
            color: 'rgba(70, 130, 180, 0.2)',
            style: 1,
            visible: true,
          },
          horzLines: {
            color: 'rgba(70, 130, 180, 0.2)',
            style: 1,
            visible: true,
          },
        },
        layout: {
          backgroundColor: 'transparent',
        },
        timeScale: {
          // rightOffset: 12,
          barSpacing: 3,
          autoScale: true,
          // fixLeftEdge: true,
          // lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          // borderColor: "#fff000",
          visible: true,
          timeVisible: true,
          secondsVisible: false
        }
      }
    }
  }

  render() {
    // data is an array of arrays, map it to array of objects with attr "data" as array
    const lineSeries = this.props.data; //.map((data: any) => ({ data }));
    return this.props.data.length > 0 ? (
      <Chart
        // from={this.props.data[0].data[0].time }
        // to={this.props.data[0].data[this.props.data.length-1].time}
        options={this.state.options}
        lineSeries={lineSeries}
        autoWidth
        autoHeight
        darkTheme
      />
    ) : 'No chart data.'
  }
}

export default ChartLineSeries