import Axios from 'axios';
import http from 'http';
import https from 'https';

const isDev = process.env.NODE_ENV === 'development';
const apiBase = isDev ? 'http://192.168.1.99:3001/' : 'https://api.havana.bitv.app/';
interface AuthHeaders {
  Authorization?: string
  Accept: string
}

// Create keepalive client
const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: true });
const axios = Axios.create({
  httpAgent, httpsAgent,
  timeout: 15000,
  maxRedirects: 10,
  maxContentLength: 5 * 1000 * 1000, // 5mb
});

function getAuthHeaders(): AuthHeaders {
  const headers: AuthHeaders = {
    Accept: 'application/json'
  };
  

  const token = localStorage.getItem('token');
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  
  return headers;
}

type MethodType = 'get' | 'post' | 'put' | 'patch' | 'delete';

async function fetch(method: MethodType, path: string, data?: any): Promise<any> {
  const headers = getAuthHeaders();

  try {
    const resp = await axios({
      method,
      url: `${apiBase}${path}`,
      data: method === 'get' ? undefined : data,
      headers,
      responseType: 'json'
    });
    // If response is paginated, return resp.data
    if (typeof resp.data?.pageSize !== 'undefined') {
      return resp.data;
    }
    if (resp.data && resp.data.data) {
      return resp.data.data;
    }
    if (resp.data && !resp.data.data) {
      return resp.data;
    }
    console.error('API returned unexpected response: ', resp);
    throw Error('Unknown error occurred');
  } catch (error: any) {
    const msg = error?.response?.data?.error?.message ?? 'Unknown error occurred.';
    console.error('fetch error:', { error, msg });
    throw Error(msg);
  }
}

export function get(path: string, data?: any): Promise<any> {
  return fetch('get', path, data);
}

export function post(path: string, data: any): Promise<any> {
  return fetch('post', path, data);
}

export function put(path: string, data: any): Promise<any> {
  return fetch('put', path, data);
}

export function patch(path: string, data: any): Promise<any> {
  return fetch('patch', path, data);
}

export function del(path: string, data?: any): Promise<any> {
  return fetch('delete', path, data);
}

export default {
  get,
  post,
  put,
  patch,
  delete: del
};